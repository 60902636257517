/* hind-300 - latin */
@font-face {
    font-family: "Hind";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/hind-v16-latin-300.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/hind-v16-latin-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/hind-v16-latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/hind-v16-latin-300.woff")
            format("woff"),
        /* Modern Browsers */ url("../fonts/hind-v16-latin-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/hind-v16-latin-300.svg#Hind")
            format("svg"); /* Legacy iOS */
}

/* hind-regular - latin */
@font-face {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/hind-v16-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/hind-v16-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/hind-v16-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../fonts/hind-v16-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */ url("../fonts/hind-v16-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/hind-v16-latin-regular.svg#Hind") format("svg"); /* Legacy iOS */
}

/* hind-600 - latin */
@font-face {
    font-family: "Hind";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/hind-v16-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/hind-v16-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/hind-v16-latin-600.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../fonts/hind-v16-latin-600.woff")
            format("woff"),
        /* Modern Browsers */ url("../fonts/hind-v16-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */ url("../fonts/hind-v16-latin-600.svg#Hind")
            format("svg"); /* Legacy iOS */
}
